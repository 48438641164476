<template>
  <section id="version-filter" v-if="versions.length > 0">
    <label v-if="label">
      {{ lang.metrics.filters.version.label[languageSelected] }}
    </label>
    <multiselect
      v-model="versionSelected"
      :showLabels="false"
      :loading="loadingVersions"
      :placeholder="lang.metrics.filters.version.placeholder[languageSelected]"
      label="tag"
      track-by="_id"
      :options="versions"
      tagPlaceholder=""
      @input="change"
    >
      <template slot="option" slot-scope="props">
        <div class="version-option">
          <span>{{ props.option.tag }}</span>
          <div v-if="props.option.active" class="version-option__status"></div>
        </div>
      </template>
      <span slot="noOptions">
        {{ lang.metrics.filters.noOptions[languageSelected] }}
      </span>
    </multiselect>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'VersionFilter',
  props: {
    selected: String,
    serviceId: {
      type: String,
      required: true
    },
    label: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      versions: [],
      versionSelected: [],
      loadingVersions: false
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('clusters', ['questionsBotVersions'])
  },
  methods: {
    ...mapActions('clusters', ['getBotVersions']),
    async loadVersions() {
      this.loadingVersions = true
      await this.getBotVersions(this.serviceId)
      this.versions = this.questionsBotVersions(this.serviceId)
      this.loadingVersions = false
    },
    change() {
      this.$emit('change', {
        type: 'version',
        value: this.versionSelected
      })
    }
  },
  async created() {
    await this.loadVersions()
    if (this.selected)
      this.versionSelected = this.versions.find(v => v._id === this.selected)
    this.change()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#version-filter {
  .version-option {
    display: flex;
    align-items: center;
    &__status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(var(--vs-success), 1);
      margin-left: 5px;
      border: solid 1px white;
    }
  }
}
</style>
